
import { defineComponent, ref, onBeforeMount, onMounted, watch } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import ModalNewAgency from "@/components/agencies/NewAgencyModal.vue";
import ModalDeleteAgency from "@/components/agencies/DeleteAgency.vue";
import Users from "@/views/colaboradores/UsersView.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../../router";
import Vehicles from "@/views/vehicles/VehiclesView.vue";
import store from "@/store";
import _ from "lodash";

export default defineComponent({
  name: "Agencies-detail",
  components: {
    ModalNewAgency,
    ModalDeleteAgency,
    Users,
    Vehicles,
  },
  setup() {
    const agencyData = ref({
      id: "",
      name: "-",
      email: "-",
      phone: "-",
      address: "-",
      cp: "-",
      state: "-",
      city: "-",
      colonia: "-",
      url_whatsapp: "",
      latitude: 19.45427274236404,
      longitude: -99.13482926287841,
    });
    const route = useRoute();
    const urlWhats = ref<string>("");
    const agencyId = ref<number>(0);
    const idToEdit = ref<number>(0);
    const tabActive = ref<number>(1);
    const type = ref<string>("new");
    const showAgency = ref<boolean>();

    const getAgency = async (id) => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/agencies/" + id;
        const { data } = await axios.get(url);
        if (data.success) {
          agencyData.value = data.data.info;
          urlWhats.value = data.data.info.url_whatsapp;
          showAgency.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const finishAction = async (evt) => {
      if (evt.type == "save") {
        Swal.fire({
          text: "Agencia creada con éxito!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
      if (evt.type == "delete") {
        Swal.fire({
          text: "Agencia eliminada con éxito!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1);
          }
        });
      }
      if (evt.type == "update") {
        Swal.fire({
          text: "Agencia actualizada con éxito!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
      await getAgency(route.params.id);
    };

    const editAgency = async (idAgency) => {
      idToEdit.value = idAgency;
      type.value = "edit";
    };

    const deleteAgency = (idAgency) => {
      agencyId.value = idAgency;
    };

    onBeforeMount(async () => {
      let userRolObj:any = localStorage.getItem('userRole');
      let userRole:any = JSON.parse(userRolObj);
      let tipoRol = userRole.tipo.name;
      let idAgency;
      if(tipoRol == 'Múltiples agencias'){
        idAgency = localStorage.getItem('agencyIdSelected');
      }else{
        idAgency = route.params.id
      }
      await getAgency(idAgency);
    });

    watch(
      () => route.params.id,
      async newId => {
        getAgency(newId);
      }
    )

    onMounted(async () => {
      agencyId.value = parseInt(route.params.id.toString());
    });

    const can = (permissions) => {
      const Permissions = store.getters.getPermissions;

      if (_.size(Permissions)) {
        if (Array.isArray(permissions)) {
          let flag = false;
          _.map(permissions, (permission) => {
            if (Permissions.indexOf(permission) !== -1) {
              flag = true;
            }
          });
          return flag;
        } else {
          return Permissions.indexOf(permissions) !== -1;
        }
      }
      return false;
    };

    return {
      agencyData,
      getAgency,
      urlWhats,
      finishAction,
      editAgency,
      deleteAgency,
      agencyId,
      type,
      idToEdit,
      can,
      showAgency,
      tabActive
    };
  },
});
